/*Base Styles*/
* {
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

html {
    background-color: white;
}

.donate-btn {
    border: solid #AF261A 3px;
    border-radius: 10px;
    color: white;
    background-color: #AF261A;
    text-align: center;
    text-decoration: none;
    display: block;
    width: 250px;
    height: auto;
    padding: 15px;
}

h1 {
    font-size: 3rem;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
}

h2 {
    font-size: 2.5rem;
}

h3 {
    font-size: 2rem;
}

.header-hero {
    background-image: url('./images/stacy-beach.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* background-position-y: -20%; */
}

@media screen and (max-width: 1200px) {
    .header-hero {
        background-image: url('./images/stacy-telescope.jpeg');
    }
}

.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    background-color: rgba(255, 255, 255);
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 0px;
    padding-bottom: 0px;
}

/*BANNER*/
.banner-img {
    background-image: url('./images/stacy-PierPlaza.jpg');
    height: 800px;
    background-size: cover;
    background-position-y: 50%;
    background-repeat: no-repeat;
    margin: 0;
}

.banner-img-two {
    background-image: url('./images/stacy-beach2.jpg');
    height: 1000px;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    margin: 0;
}

/*HEADER*/

.nav-btns {
    padding: 5px;
    width: 90%;
}


.navlink:hover {
    text-decoration: underline solid #AF261A;
}

.stacyS-logo {
    height: 85px;
    width: 85px;
}

/*HERO*/
.about-main,
.stacy-footer {
    background-color: #143d78;
    color: white;
}

/*ENDORSEMENTS*/

.endorsements-main h2 {
    text-align: center;
}

@media screen and (max-width: 768px) {
    .endorsements-item {
        border-bottom: 4px solid #AF261A;
        margin-bottom: 1px;
    }
}

.endorsements-item:nth-child(-n + 2) {
    border-bottom: 4px solid #AF261A;
    margin-bottom: 1px;
}

/*Fade effect */
.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
}

.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}